<template>
	<div class="thumb" :class="{ square }">
		<el-image :src="src" fit="cover">
			<slot name="error">
				<div slot="error" class="center" style="width:100%; height:100%; background:rgba(192, 169, 114, 0.8);">
					<img src="@/assets/logo-white.png" width="80%">
				</div>
			</slot>
		</el-image>
	</div>
</template>

<script>
export default {
	props: {
		src: String,
		square: Boolean
	}
}
</script>

<style lang="scss" scoped>
.thumb {
	position: relative;

	&::before {
		content: "";
		display: block;
		padding-top: 60%;
	}

	&.square {
		&::before {
			padding-top: 100%;
		}
	}

	.el-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
</style>